import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components';

import Layout from '../components/layout';
import { H1 } from '../components/Headings';

const Wrapper = styled.div`
  min-height: 700px;
  max-width: ${props => props.theme.contentWidth};
  padding: 15px;
  margin: auto;
`;

const StyledH1 = styled(H1)`
  text-align: center;
`

const Content = styled.div`
  font-family: "Montserrat"
`;

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Wrapper>
        <StyledH1>{frontmatter.title}</StyledH1>

        <Content
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Wrapper>>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
